import React from 'react';
import {RouterProvider} from 'react-router-dom';
import router from "./router";
import Footer from "./components/footer";

function App() {
    return (
        <div className="container">
            <RouterProvider router={router}/>
            <Footer></Footer>
        </div>
    );
}

export default App;
