import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Tabs} from 'antd-mobile';
import type {MenuProps} from 'antd';
import {Menu} from 'antd';
import logo from '../../assets/logo.png';
import styles from './index.module.less';

import {useMediaQuery} from 'react-responsive';

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
    {
        label: '首页',
        key: 'home'
    },
    {
        label: '技术积累',
        key: 'jiLei',
        popupOffset: [100, 100]
    },
    {
        label: '关于达拓',
        key: 'about'
    }
];

const Header = ({props}: any) => {
    const isMobile = useMediaQuery({query: '(max-width: 768px)'});
    const [current, setCurrent] = useState(props);
    const [activeKeyCurrent, setActiveKeyCurrent] = useState(props);
    const navigate = useNavigate();
    const onClick: MenuProps['onClick'] = (e: any) => {
        // console.log('click ', e);
        setCurrent(e.key);
        if (e.key === 'home') {
            // console.log('home')
            navigate('/home')
        } else if (e.key === 'jiLei') {
            // console.log('jishu')
            navigate('/jishu')
        } else if (e.key === 'about') {
            // console.log('about')
            navigate('/about')
        }
    };

    const onTabsChange = (e: any) => {
        // console.log('tabsChange', e);
        setActiveKeyCurrent(e);
        if (e === 'home') {
            // console.log('home')
            navigate('/home')
        } else if (e === 'jiLei') {
            // console.log('jishu')
            navigate('/jishu')
        } else if (e === 'about') {
            // console.log('about')
            navigate('/about')
        }
    }
    // console.log('isMobile', isMobile);
    return isMobile ? (
        <div className={styles.headerApp}>
            <img className={styles.logo} src={logo} alt=""/>
            <div className={styles.menu}>
                <Tabs activeKey={activeKeyCurrent} onChange={onTabsChange} style={{
                    '--title-font-size': '10px',
                    '--active-title-color': '#FB1915',
                    '--active-line-color': '#FB1915'
                }}>
                    <Tabs.Tab title='首页' key='home'></Tabs.Tab>
                    <Tabs.Tab title='技术积累' key='jiLei'></Tabs.Tab>
                    <Tabs.Tab title='关于达拓' key='about'></Tabs.Tab>
                </Tabs>
            </div>
        </div>
    ) : (
        <div className={styles.header}>
            <img className={styles.logo} src={logo} alt=""/>
            <div className={styles.menu}>
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal"
                      items={items}/>
            </div>
        </div>
    )
}
export default Header;
