import React from 'react';
import {useMediaQuery} from "react-responsive";
import {Divider} from 'antd';
import styles from './index.module.less'

const Footer = () => {
    const isMobile = useMediaQuery({query: '(max-width: 768px)'});
    return (
        <div className={isMobile ? styles.footerApp : styles.footer}>
            © 2020 Company, Inc
            <Divider type="vertical"/>
            <a href="#">达拓智通</a>
            <Divider type="vertical"/>
            <a target="_blank" href="https://beian.miit.gov.cn/">蜀ICP备16021647号-1</a>
        </div>
    )
}
export default Footer
