import React from "react";
import styles from "../home/index.module.less";
import Header from "../../components/header";
import banner from "../../assets/banner.png";
import technologyAccumulation from "../../assets/jishu_main_jilei.png";
import ownership from "../../assets/jishu_main_chanquan.png";

const TechnologyAccumulation = () => {
    return (
        <div>
            <div className={styles.header}>
                <Header props="jiLei"/>
            </div>
            <img width="100%" src={banner} style={{display: 'block'}} alt=""/>
            <img width="100%" src={technologyAccumulation} alt=""/>
            <img width="100%" src={ownership} style={{display: 'block'}} alt=""/>
        </div>
    )
}
export default TechnologyAccumulation
