import React from 'react';
import {createBrowserRouter, Navigate} from "react-router-dom";
import Home from "../pages/home";
import About from "../pages/about";
import TechnologyAccumulation from "../pages/technologyAccumulation";


const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to="/home" replace/>,
    },
    {
        path: '/home',
        element: <Home/>,
    },
    {
        path: '/jishu',
        element: <TechnologyAccumulation/>,
    },
    {
        path: '/about',
        element: <About/>,
    }
])

export default router
