import React from 'react';
import banner from '../../assets/banner.png';
import dataLkIntroduce from '../../assets/home_main_js.png';
import merit from '../../assets/home_main_zy.png';
import partners from '../../assets/home_main_hzhb.png';
import Header from "../../components/header";
import styles from './index.module.less';

const Home = () => {
    return (
        <div>
            <div className={styles.header}>
                <Header props="home" />
            </div>
            <img width="100%" src={banner} alt=""/>
            <img width="100%" src={dataLkIntroduce} alt=""></img>
            <img width="100%" src={merit} alt=""></img>
            <img width="100%" src={partners} alt=""></img>
            {/*<Footer/>*/}
        </div>
    )
}
export default Home
