import React from "react";
import styles from "../home/index.module.less";
import Header from "../../components/header";
import banner from "../../assets/about_banner.png";
import introduced from "../../assets/about_mian_js.png";
import forwardScript from "../../assets/about_main_qjjb.png";
import contactUs from "../../assets/about_main_lianxi.png";

const About = () => {
    return (
        <div>
            <div className={styles.header}>
                <Header props="about"/>
            </div>
            <img width="100%" src={banner} style={{display: 'block'}} alt=""/>
            <img width="100%" src={introduced} alt=""/>
            <img width="100%" src={forwardScript} style={{display: 'block'}} alt=""/>
            <img width="100%" src={contactUs} style={{display: 'block'}} alt=""/>
        </div>
    )
}
export default About
